<script setup>
const props = defineProps({ blok: Object });

const allFaqs = await useFaqs().all();
const config = await useConfig();

function findFaqStory(uuid) {
  return allFaqs.find((f) => f.uuid === uuid);
}

function mapFaq(uuid) {
  const story = findFaqStory(uuid);
  const content = story.content;

  return {
    title: content.title || story.name,
    text: content.text,
  };
}

function faqReferences(faq) {
  return faq.faqs.map((uuid) => {
    return mapFaq(uuid);
  });
}

const faqs = [];
props.blok.blocks.forEach((faq) => {
  if (faq.faqs) {
    const references = faqReferences(faq);
    faqs.push(...references);
  } else {
    faqs.push(faq);
  }
});

function replaceTitle() {
  const element = document.querySelector(".section-heading");

  let content = element.innerHTML;

  // Use regex to replace ##...##
  content = content.replace(/##(.*?)##/g, '<span class="text-color">$1</span>');

  element.innerHTML = content;
}

onMounted(() => {
  replaceTitle();
});

const items = [
  {
    text: "Payment questions",
  },
  {
    text: "Refund questions",
  },
  {
    text: "foo bar",
  },
];
</script>
<template>
  <BaseSection :blok="{ ...blok, blocks: [] }">
    <div class="w-layout-grid accordion-parent-grid">
      <div class="content">
        <div class="content-sticky">
          <div class="fade-in-on-scroll">
            <h3 class="section-heading">
              {{ config.faq_title }}
            </h3>
          </div>
          <div class="fade-in-on-scroll">
            <div v-html="renderRichText(config.faq_text)" />
          </div>
          <div class="fade-in-on-scroll">
            <ul role="list" class="list with-top-space">
              <li
                v-for="(item, i) in items"
                :key="i"
                class="list-item-with-checkicon"
              >
                <div>{{ item.text }}</div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div
        id="w-node-_2204bd44-fcda-089d-5bdb-bc7e2b982f73-4e2e1336"
        class="content"
      >
        <div class="accordion-list faq-list">
          <v-expansion-panels variant="accordion">
            <v-expansion-panel
              v-for="(faq, i) in faqs"
              :key="i"
              :text="faq.text"
              :title="faq.title"
            ></v-expansion-panel>
          </v-expansion-panels>
        </div>
      </div>
    </div>
  </BaseSection>
</template>

<style lang="scss">
.faq-list {
  .v-expansion-panels {
    grid-column-gap: 2em;
    grid-row-gap: 2em;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    display: flex;
  }
  .v-expansion-panels--variant-accordion > :first-child:not(:last-child),
  .v-expansion-panels--variant-accordion > :not(:first-child):not(:last-child),
  .v-expansion-panels--variant-accordion > :last-child:not(:first-child) {
    border-radius: 12px !important;
  }

  .v-expansion-panel {
    grid-column-gap: 0em;
    grid-row-gap: 0em;
    background-color: rgba(255, 255, 255, 0.05);
    border-radius: 12px !important;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    transition: box-shadow 0.4s;
    display: flex;
    border: 1px solid rgba(255, 255, 255, 0.08);
    transition: all 0.3s;
    &:hover {
      border: 1px solid rgba(255, 255, 255, 0.15);
    }
  }
  .v-expansion-panel-title {
    color: var(--1-main-colors--white);
    font-size: 20px;
  }
}
</style>
